import { render, staticRenderFns } from "./materialConsumption.vue?vue&type=template&id=cb31046a&scoped=true"
import script from "./materialConsumption.vue?vue&type=script&lang=js"
export * from "./materialConsumption.vue?vue&type=script&lang=js"
import style0 from "./materialConsumption.vue?vue&type=style&index=0&id=cb31046a&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb31046a",
  null
  
)

export default component.exports