<template>
    <div class="materialConsumption table-bg" v-loading="loading">
        <el-table
            :data="tableList"
            style="width: 100%"
            class="table-list"
            height="100%"
        >
            <el-table-column
                prop="gklcbh"
                label="料仓号"
            >
            </el-table-column>
            <el-table-column
                prop="material_child_name"
                label="原材料"
                class-name="notice-cell"
            >
            </el-table-column>
            <el-table-column
                prop="material_name"
                label="规格"
            >
            </el-table-column>
            <el-table-column
                prop="ylshijyl"
                label="配比用量(千克)"
            >
            </el-table-column>
            <el-table-column
                prop="ylsjyl"
                label="实际用量(千克)"
            >
            </el-table-column>
            <el-table-column
                prop="error_rate"
                label="误差"
            >
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    name: 'material-consumption',
    props: {
        extr: {
            type: [Array, Object, String ],
            required: true, // 是否必传
        },
    },
    data() {
        return {
            loading: false,
            tableList: [],
        };
    },
    created() {
        // 初始化页面数据
        this.getInit();
    },
    methods: {
        // 初始化页面数据
        getInit() {
            this.loading = true;
            this.$axios
                .get(`/interfaceApi/production/productionexternal/producttask/detail/${this.extr.pcb_number}`)
                .then(res => {
                    if (res) {
                        this.tableList = res.Consumes;
                    }
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
};
</script>

<style lang="stylus" scoped>
.materialConsumption
    width 100%;
    height 100%;
    >>> .el-table
        border-collapse collapse
        th,td
            height 0.4rem
            padding 0
        th
            text-align center
            background #EDF0F5
            color #022782
            .cell
                font-size 0.14rem
        td
            color #333333
            font-size 0.14rem
        .hight-cell
            color #FF4D4F
            background: #FFF1F0;
</style>
